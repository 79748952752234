import {
  Button,
  IconButton,
  MenuItem,
  Modal,
  Paper,
  Switch,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Grid,
} from "@material-ui/core";
import React, { useEffect } from "react";
import { useState } from "react";
import { ArrowBack, ArrowForward, InfoOutlined } from "@material-ui/icons";
import { getSuperAdminService } from "service/service";

const GiftClaimRecords = () => {
  const [infoModal, setInfoModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [offset, setOffset] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [formType, setFormType] = useState(null);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState(null);
  const [dispatch_status, setDispatch_status] = useState(null);
  const [column, setColumns] = useState([
    { title: "Sr No.", field: "no." },
    { title: "Student Name", field: "full_name" },
    { title: "Mobile", field: "mobile" },
    { title: "City", field: "student_district_name" },
    { title: "Entry Date", field: "date" },
    { title: "Type", field: "form_type" },
    { title: "Dispatch", field: "dispatch" },
  ]);
  const [data, setData] = useState([]);

  const handleOpenModal = (row) => {
    setSelectedRow(row);
    setInfoModal(true);
  };

  const handleCloseModal = () => {
    setInfoModal(false);
    setSelectedRow(null);
  };
  const getStudentList = async () => {
    let url = `/premium/get_filled_form_details/?page=${offset}&page_size=10`;
    if (formType !== null) {
      url = `/premium/get_filled_form_details/?page=${offset}&page_size=10&form_type=${formType}`;
    }
    if (dispatch_status !== null) {
      url = `/premium/get_filled_form_details/?page=${offset}&page_size=10&dispatch_status=${dispatch_status}`;
    }
    if (startDate !== null && endDate !== null) {
      url = `/premium/get_filled_form_details/?page=${offset}&page_size=10&start_date=${startDate}&end_date=${endDate}`;
    }
    if (formType !== null && dispatch_status !== null) {
      url = `/premium/get_filled_form_details/?page=${offset}&page_size=10&form_type=${formType}&dispatch_status=${dispatch_status}`;
    }
    if (startDate !== null && endDate !== null && formType !== null) {
      console.log("Form Type");
      url = `/premium/get_filled_form_details/?page=${offset}&page_size=10&start_date=${startDate}&end_date=${endDate}&form_type=${formType}`;
    }
    if (
      formType !== null &&
      startDate !== null &&
      endDate !== null &&
      formType !== null &&
      dispatch_status !== null
    ) {
      url = `/premium/get_filled_form_details/?page=${offset}&page_size=10&form_type=${formType}&start_date=${startDate}&end_date=${endDate}&form_type=${formType}&dispatch_status=${dispatch_status}`;
    }
    try {
      const response = await getSuperAdminService().getListOFFormFilledUsers(
        url
      );
      if (response?.status === 200) {
        setData(response?.data?.data);
        setTotalCount(response?.data?.total_count);
      }
    } catch (err) {
      console.log("Something went wrong", err);
    }
  };

  const fetchNextPageData = () => {
    if (offset * 10 < totalCount) {
      setOffset(offset + 1);
    }
  };
  const fetchPreviousPageData = () => {
    if (offset > 1) {
      setOffset(offset - 1);
    }
  };
  useEffect(() => {
    getStudentList();
  }, []);

  useEffect(() => {
    getStudentList();
  }, [formType, dispatch_status, offset, endDate]);

  const editUserStaus = async (status, id) => {
    const payload = {
      dispatch_status: !status,
      form_id: id,
    };
    try {
      const response = await getSuperAdminService().updateUserStatus(payload);
      if (response?.status === 201) {
        getStudentList();
      }
    } catch (err) {
      console.log("Something went wrong", err);
    }
  };

  const handleReset = () => {
    setStartDate("");
    setEndDate("");
    setFormType(null);
    setDispatch_status(null);
  };

  const totalPages = Math.ceil(totalCount / 10);
  const currentPage = offset;

  return (
    <>
      <div style={{ width: "100%" }}>
        <h4 style={{ color: "black" }}>Gift Claim Records</h4>
        <div style={{ width: "100%", marginTop: "2rem" }}>
          <Grid
            container
            spacing={1}
            alignItems="center"
            justifyContent="space-between"
          >
            <Grid item xs={12} sm={2}>
              <div style={{ color: "black", paddingBottom: 10 }}>
                Start Date
              </div>
              <TextField
                fullWidth
                variant="outlined"
                type="date"
                size="small"
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
              />
            </Grid>

            <Grid item xs={12} sm={2}>
              <div style={{ color: "black", paddingBottom: 10 }}>End Date</div>
              <TextField
                fullWidth
                variant="outlined"
                type="date"
                size="small"
                value={endDate}
                onChange={(e) => setEndDate(e.target.value)}
              />
            </Grid>

            <Grid item xs={12} sm={2}>
              <div style={{ color: "black", paddingBottom: 10 }}>Type</div>
              <TextField
                fullWidth
                select
                variant="outlined"
                size="small"
                value={formType}
                onChange={(e) => setFormType(e.target.value)}
              >
                <MenuItem value="premium">Premium</MenuItem>
                <MenuItem value="daily_streak">Daily-Streak</MenuItem>
              </TextField>
            </Grid>

            <Grid item xs={12} sm={2}>
              {" "}
              {/* Decreased width here */}
              <div style={{ color: "black", paddingBottom: 10 }}>Dispatch</div>
              <TextField
                fullWidth
                select
                variant="outlined"
                size="small"
                value={dispatch_status}
                onClick={(e) => setDispatch_status(e.target.value)}
              >
                <MenuItem value="true">Yes</MenuItem>
                <MenuItem value="false">No</MenuItem>
              </TextField>
            </Grid>

            <Grid
              item
              xs={12}
              sm={1}
              style={{ marginTop: "20px", marginRight: "50px" }}
            >
              <Button
                fullWidth
                variant="outlined"
                onClick={handleReset}
                style={{ border: "1px solid", padding: 8 }}
              >
                Reset
              </Button>
            </Grid>
          </Grid>
        </div>

        <div style={{ width: "90%", marginLeft: "4rem", marginTop: "3rem" }}>
          <TableContainer component={Paper}>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  {column.map((column) => (
                    <TableCell key={column.field}>{column.title}</TableCell>
                  ))}
                  <TableCell
                    style={{ marginLeft: "1rem", visibility: "hidden" }}
                  >
                    Update
                  </TableCell>
                </TableRow>
              </TableHead>

              {data?.map((row, index) => (
                <TableRow key={row.id} className="table-row-hover">
                  {column.map((column) => (
                    <TableCell key={column.field}>
                      {column.field === "no."
                        ? index + 1
                        : column.field === "dispatch"
                        ? row[column.field] === true
                          ? "Yes"
                          : "No"
                        : column.field === "form_type"
                        ? row?.form_type !== "daily_streak"
                          ? "Premium Resource"
                          : "Daily-Streak"
                        : row[column.field]}
                    </TableCell>
                  ))}
                  <TableCell>
                    <Tooltip title="Info" arrow>
                      <IconButton
                        aria-label="edit"
                        onClick={() => handleOpenModal(row)}
                      >
                        <InfoOutlined />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              ))}
            </Table>
          </TableContainer>
        </div>
      </div>
      <div
        style={{
          marginTop: "1.5rem",
          display: "flex",
          justifyContent: "center",
          gap: "1rem",
        }}
      >
        <Button
          disabled={offset === 1}
          onClick={() => {
            fetchPreviousPageData();
          }}
        >
          <ArrowBack style={{ color: "black", fontSize: "30px" }} />
        </Button>
        <div style={{ marginTop: "0.5rem" }}>
          Showing Page {currentPage} out of {totalPages}
        </div>
        <Button
          onClick={() => {
            fetchNextPageData();
          }}
          disabled={offset >= totalPages}
        >
          <ArrowForward style={{ color: "black", fontSize: "30px" }} />
        </Button>
      </div>
      <Modal
        open={infoModal}
        onClose={handleCloseModal}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 600,
            backgroundColor: "white",
            padding: "16px",
            boxShadow: 24,
          }}
        >
          <div
            id="simple-modal-title"
            style={{
              textAlign: "center",
              backgroundColor: "#924ED6",
              color: "white",
              padding: "0.5rem",
            }}
          >
            Student Info
          </div>
          {selectedRow && (
            <>
              <div
                id="simple-modal-description"
                style={{ width: "100%", marginTop: "1.5rem" }}
              >
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <p>
                    <strong>Student Name:</strong> {selectedRow?.student_name}
                  </p>
                  <p>
                    <strong>Mobile:</strong> {selectedRow?.mobile}
                  </p>
                </div>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <p>
                    <strong>District:</strong> {selectedRow?.district}
                  </p>
                  <p>
                    <strong>Pin-Code:</strong> {selectedRow?.pin_code}
                  </p>
                </div>
                <p style={{ marginBottom: "0rem" }}>
                  <strong>Complete Adress:</strong>
                </p>
                <p>{selectedRow?.address}</p>
              </div>
              <div style={{ textAlign: "center" }}>
                <div
                  style={{
                    fontWeight: "bold",
                    borderBottom: "0.5px solid lightgrey",
                    display: "inline-block",
                  }}
                >
                  Applicable Gift Details
                </div>
                <div style={{ marginTop: "0.5rem" }}>
                  {selectedRow?.details}
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "3rem",
                }}
              >
                <div style={{ marginTop: "0.5rem" }}>Gift Dispatched?</div>

                <Switch
                  checked={selectedRow?.dispatch === true}
                  onChange={() => {
                    // Optimistic update of the dispatch status
                    setSelectedRow({
                      ...selectedRow,
                      dispatch: !selectedRow?.dispatch,
                    });

                    // Call the function to update the status on the backend
                    editUserStaus(selectedRow?.dispatch, selectedRow?._id); // Ensure `id` field is correct
                  }}
                  color="primary"
                  name="dispatch"
                  inputProps={{ "aria-label": "primary checkbox" }}
                />
              </div>
            </>
          )}
        </div>
      </Modal>
    </>
  );
};

export default GiftClaimRecords;
