import React, { useEffect, useState } from "react";
import {
  Select,
  FormControl,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  IconButton,
  CircularProgress,
} from "@material-ui/core";
import { getSuperAdminService } from "service/service";
import { useHistory } from "react-router-dom";
import deleteIcon from "../../assets/img/delete.svg";
import downloadIcon from "../../assets/img/download.svg";
import editIcon from "../../assets/img/edit.svg";
import { offset } from "highcharts";
import moment from "moment";

const ITEMSPERPAGE = 10;

const LanguageToolRecords = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [subjects, setSubjects] = useState([]);
  const [allLearningItems, setAllLearningItems] = useState([]);
  const [allLearningObjects, setAllLearningObjects] = useState({});
  const [allLevels, setAllLevels] = useState([]);
  const [selectedSubject, setSelectedSubject] = useState(0);
  const [learningItem, setLearningItem] = useState(0);
  const [selectedLevel, setSelectedLevel] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  const history = useHistory();

  const handleRedirect = (data) => {
    history.push("/admin/languagetool", data);
  };

  useEffect(() => {
    const getSubjects = async () => {
      const result = await getSuperAdminService().getFLNLearningSubjects();
      result && setSubjects(result?.data?.results);
      console.log("result:", result?.data?.results);
    };

    getSubjects();
  }, []);

  const getLearningItems = async (id) => {
    const result = await getSuperAdminService().getFLNLearningItems(id);
    result && setAllLearningItems(result?.data?.results);
    console.log("LearningItems result:", result?.data?.results);
  };

  const getLevels = async (id) => {
    const result = await getSuperAdminService().getFLNLevels(id);
    result && setAllLevels(result?.data?.results);
    console.log("Levels result:", result?.data?.results);
  };

  const getLearningObjects = async (params) => {
    try {
      const result = await getSuperAdminService().getFLNLearningObjects(params);
      if (result) {
        setAllLearningObjects(result?.data);
        setIsLoading(false);
      }
      console.log("LearningObjects result:", result?.data);
    } catch (error) {
      setIsLoading(false);
      console.error("Error fetching learning objects", error);
    }
  };

  const getExcelSheet = async (params) => {
    try {
      const result = await getSuperAdminService().downloadFLNData(params);
      console.log("download result:", result);
      if (result?.status === 200) {
        const currentDate = moment().format("DDMMYY");
        const fileName = `fln_learning_tool_questions_data_${currentDate}.csv`;

        const blob = new Blob([result?.data], { type: "text/csv" });
        const url = window.URL.createObjectURL(blob);

        // Create a link element and trigger the download
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();

        // Clean up and remove the link element
        link.parentNode.removeChild(link);
      } else {
        console.log("Error:", result?.status.result?.statusText);
      }
    } catch (error) {
      console.error("There was an error downloading the file:", error);
    }
  };

  const getLearningObjectsWithPage = async (params) => {
    const result = await getSuperAdminService().getFLNObjectsUsingURL(params);
    result && setAllLearningObjects(result?.data);
    console.log("LearningObjects result:", result?.data);
  };

  const deleteLearningRecord = async (id) => {
    try {
      const result = await getSuperAdminService().deleteFLNLearningRecord(id);
      console.log("deleteRecord result:", result);
      const data = allLearningObjects?.results?.filter((obj) => obj.id !== id);
      const newData = { ...allLearningObjects };
      newData.results = data;
      setAllLearningObjects(newData);
    } catch (error) {
      console.log("delete error:", error);
    }
  };

  const handleSubjectChange = (event) => {
    if (selectedLevel !== 0) {
      setLearningItem(0);
    }
    if (learningItem !== 0) {
      setSelectedLevel(0);
    }
    setSelectedSubject(Number(event.target.value));
    getLearningItems(Number(event.target.value));
  };

  const handleLearningItemChange = (event) => {
    if (selectedLevel !== 0) {
      setSelectedLevel(0);
    }
    setLearningItem(Number(event.target.value));
    getLevels(Number(event.target.value));
  };

  const handleLevelChange = (event) => {
    setIsLoading(true);
    setSelectedLevel(Number(event.target.value));
    const params = {
      subject_id: selectedSubject,
      reading_level_id: learningItem,
      difficulty_level_id: Number(event.target.value),
    };
    getLearningObjects(params);
  };

  const handleDownloadExcel = () => {
    const params = {
      subject_id: selectedSubject,
      reading_level_id: learningItem,
      difficulty_level_id: selectedLevel,
    };
    getExcelSheet(params);
  };

  const totalPages =
    allLearningObjects?.count > 0 &&
    Math.ceil(allLearningObjects?.count / ITEMSPERPAGE);

  const handlePrevious = () => {
    const params = {
      subject_id: selectedSubject,
      reading_level_id: learningItem,
      difficulty_level_id: selectedLevel,
      limit: ITEMSPERPAGE,
      offset: (currentPage - 2) * ITEMSPERPAGE,
    };
    getLearningObjectsWithPage(params);
    setCurrentPage(currentPage - 1);
  };

  const handleNext = () => {
    const params = {
      subject_id: selectedSubject,
      reading_level_id: learningItem,
      difficulty_level_id: selectedLevel,
      limit: ITEMSPERPAGE,
      offset: currentPage * ITEMSPERPAGE,
    };
    getLearningObjectsWithPage(params);
    setCurrentPage(currentPage + 1);
  };

  const handlePreviousStart = () => {
    const params = {
      subject_id: selectedSubject,
      reading_level_id: learningItem,
      difficulty_level_id: selectedLevel,
      limit: ITEMSPERPAGE,
      offset: 0,
    };
    getLearningObjectsWithPage(params);
    setCurrentPage(1);
  };

  const handleNextEnd = () => {
    const params = {
      subject_id: selectedSubject,
      reading_level_id: learningItem,
      difficulty_level_id: selectedLevel,
      limit: ITEMSPERPAGE,
      offset: (totalPages - 1) * ITEMSPERPAGE,
    };
    getLearningObjectsWithPage(params);
    setCurrentPage(totalPages);
  };

  return (
    <>
      <div
        className="whiteBackground milestones vh100"
        style={{
          padding: "30px",
          paddingTop: "50px",
          backgroundColor: "#FFFFFF",
        }}
      >
        <div style={{ fontSize: 40, fontWeight: "700" }}>Records</div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            marginTop: "7%",
            width: "90%",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              gap: "20px",
              alignItems: "center",
            }}
          >
            <div style={{ fontSize: "20px", fontWeight: "700" }}>
              Select Subject
            </div>
            <div>
              <FormControl variant="outlined" style={{ minWidth: 240 }}>
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  value={selectedSubject}
                  onChange={handleSubjectChange}
                  label="Subject"
                >
                  <MenuItem value={0} disabled>
                    All
                  </MenuItem>
                  {subjects &&
                    subjects?.map((subject) => (
                      <MenuItem key={subject?.id} value={subject?.id}>
                        {subject?.name}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </div>
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              gap: "20px",
              alignItems: "center",
            }}
          >
            <div style={{ fontSize: "20px", fontWeight: "700" }}>
              Select Learning Item
            </div>
            <div>
              <FormControl variant="outlined" style={{ minWidth: 240 }}>
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  value={learningItem}
                  onChange={handleLearningItemChange}
                  label="Learning Item"
                >
                  <MenuItem value={0} disabled>
                    All
                  </MenuItem>
                  {allLearningItems &&
                    allLearningItems?.map((item) => (
                      <MenuItem key={item?.id} value={item?.id}>
                        {item?.name}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </div>
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              gap: "20px",
              alignItems: "center",
            }}
          >
            <div style={{ fontSize: "20px", fontWeight: "700" }}>
              Select Level
            </div>
            <div>
              <FormControl variant="outlined" style={{ minWidth: 240 }}>
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  value={selectedLevel}
                  onChange={handleLevelChange}
                  label="Level"
                >
                  <MenuItem value={0} disabled>
                    All
                  </MenuItem>
                  {allLevels &&
                    allLevels?.map((level) => (
                      <MenuItem key={level?.id} value={level?.id}>
                        {level?.name}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </div>
          </div>
        </div>
        {allLearningObjects?.count > 0 ? (
          <div style={{ width: "90%" }}>
            <div style={{ marginTop: "50px" }}>
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 450 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="center">Subject</TableCell>
                      <TableCell align="center">Item</TableCell>
                      <TableCell align="center">Level</TableCell>
                      <TableCell align="center">Text</TableCell>
                      <TableCell align="center"></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {allLearningObjects?.results?.map((row) => (
                      <TableRow
                        key={row?.id}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="row" align="center">
                          {row?.subject_name}
                        </TableCell>
                        <TableCell align="center">
                          {row?.reading_level_name}
                        </TableCell>
                        <TableCell align="center">
                          {row?.difficulty_level_name}
                        </TableCell>
                        <TableCell align="center">{row?.text}</TableCell>
                        <TableCell align="center">
                          <IconButton
                            color="primary"
                            aria-label="upload picture"
                            component="span"
                            onClick={() => {
                              handleRedirect(row);
                            }}
                          >
                            <img src={editIcon} alt="edit" />
                          </IconButton>
                          <IconButton
                            color="primary"
                            aria-label="upload picture"
                            component="span"
                            style={{ marginLeft: 5 }}
                            onClick={() => {
                              deleteLearningRecord(row?.id);
                            }}
                          >
                            <img src={deleteIcon} alt="edit" />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>

            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginTop: 50,
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-end",
                  alignItems: "center",
                  gap: 25,
                  width: "276px",
                  height: "65px",
                  backgroundColor: "#7965DA",
                  borderRadius: 22,
                  paddingRight: 5,
                  cursor: "pointer",
                }}
                onClick={handleDownloadExcel}
              >
                <div
                  style={{
                    fontSize: 20,
                    fontWeight: "700",
                    alignItems: "center",
                    color: "#FFFFFF",
                  }}
                >
                  Download All
                </div>
                <IconButton
                  color="primary"
                  aria-label="upload picture"
                  component="span"
                >
                  <img src={downloadIcon} alt="download" />
                </IconButton>
              </div>
            </div>

            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginTop: 50,
                gap: 100,
              }}
            >
              <div
                style={{
                  display: "flex",
                  gap: 20,
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    height: "28px",
                    width: "51px",
                    backgroundColor: allLearningObjects?.previous
                      ? "#658884"
                      : "#e0e0e0",
                    borderRadius: 10,
                    cursor: allLearningObjects?.previous
                      ? "pointer"
                      : "default",
                    border: "1px solid #DFDFDF",
                    boxShadow: "2px 3px 0px 0px #F0DCEB",
                  }}
                  onClick={
                    allLearningObjects?.previous ? handlePreviousStart : null
                  }
                >
                  <div
                    style={{
                      color: "#FFFFFF",
                      fontSize: 18,
                      fontWeight: "600",
                    }}
                  >
                    {"<<"}
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    height: "28px",
                    width: "23px",
                    backgroundColor: allLearningObjects?.previous
                      ? "#658884"
                      : "#e0e0e0",
                    borderRadius: 10,
                    cursor: allLearningObjects?.previous
                      ? "pointer"
                      : "default",
                    border: "1px solid #DFDFDF",
                    boxShadow: "2px 3px 0px 0px #F0DCEB",
                  }}
                  onClick={allLearningObjects?.previous ? handlePrevious : null}
                >
                  <div
                    style={{
                      color: "#FFFFFF",
                      fontSize: 18,
                      fontWeight: "600",
                    }}
                  >
                    {"<"}
                  </div>
                </div>
              </div>
              <div style={{ fontSize: 18, fontWeight: "600" }}>
                Page {currentPage}/{totalPages}
              </div>
              <div
                style={{
                  display: "flex",
                  gap: 20,
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    height: "28px",
                    width: "23px",
                    backgroundColor: allLearningObjects?.next
                      ? "#658884"
                      : "#e0e0e0",
                    borderRadius: 10,
                    cursor: allLearningObjects?.next ? "pointer" : "default",
                    border: "1px solid #DFDFDF",
                    boxShadow: "2px 3px 0px 0px #F0DCEB",
                  }}
                  onClick={allLearningObjects?.next ? handleNext : null}
                >
                  <div
                    style={{
                      color: "#FFFFFF",
                      fontSize: 18,
                      fontWeight: "600",
                    }}
                  >
                    {">"}
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    height: "28px",
                    width: "51px",
                    backgroundColor: allLearningObjects?.next
                      ? "#658884"
                      : "#e0e0e0",
                    borderRadius: 10,
                    cursor: allLearningObjects?.next ? "pointer" : "default",
                    border: "1px solid #DFDFDF",
                    boxShadow: "2px 3px 0px 0px #F0DCEB",
                  }}
                  onClick={allLearningObjects?.next ? handleNextEnd : null}
                >
                  <div
                    style={{
                      color: "#FFFFFF",
                      fontSize: 18,
                      fontWeight: "600",
                    }}
                  >
                    {">>"}
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : isLoading ? (
          <div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "60vh",
                width: "100%",
              }}
            >
              <CircularProgress />
            </div>
          </div>
        ) : selectedLevel ? (
          <div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "60vh",
                width: "100%",
                fontSize: 20,
              }}
            >
              No data found with selected Input. Please try again with a
              different selection.
            </div>
          </div>
        ) : (
          <div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "60vh",
                width: "100%",
                fontSize: 20,
              }}
            >
              Please select all the values to get the results.
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default LanguageToolRecords;
